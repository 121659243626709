import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Hero from "../../../components/Hero/hero";
import VideoQuote from "../../../components-v2/VideoQuote";
import {
  newYorktHero,
  newYorkWhiteGlove,
  newYorkSubverticals,
  unifiedExportLargeFeatures,
  quoteWithVideo,
  newYorkSuccess,
  momoyaSlides,
} from "../../../data/landing/new-york-data";

import ogImage from "../../../images/global_assets/og-image.png";

const whiteGloveImport = "new-york-white-glove.png";
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const Subverticals = loadable(() =>
  import("../../../components/SubVerticals/Subverticals")
);

const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const CapterraBanner = loadable(() =>
  import("../../../components-v2/CapterraBanner")
);
const TopRestaurantsLogos = loadable(() =>
  import("../../../components-v2/TopRestaurantsLogos")
);

const NewYork = () => {
  return (
    <Layout
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="NYC Restaurant POS | All-In-One Software For Restaurants | SpotOn"
        description="SpotOn is the #1 rated restaurant point of sale system. We offer expert local support for restaurants in New York."
        image={`https://spoton.com/${ogImage}`}
        productSchemaSR
      />
      <Hero sectionData={newYorktHero} />
      <TopRestaurantsLogos
        desktopImageName="new-york-logos.png"
        mobileImageName="new-york-logos-vertical.png"
      />

      <main>
        <WhiteGlove
          sectionData={newYorkWhiteGlove}
          whiteGloveBg={whiteGloveImport}
        />
        <Subverticals sectionData={newYorkSubverticals} />
        {unifiedExportLargeFeatures.map((sectionData, idx) => (
          <LargeFeatures
            key={sectionData.toString() + idx.toString()}
            sectionData={sectionData}
          />
        ))}
        <SuccessStories sectionData={newYorkSuccess} slides={momoyaSlides} />
        <VideoQuote className="mt-20 mb-24" {...quoteWithVideo} />
        <CapterraBanner
          className="mt-16 lg:mt-20 mb-20 lg:mb-40"
          imageName="capterra-restaurant-pos.png"
          title={`<span class="text-primary">The #1</span> restaurant POS system according to user reviews<span class="text-primary">.</span>`}
          subtitle="Rated the best in every category by restaurant operators."
        />
      </main>
    </Layout>
  );
};

export default NewYork;
