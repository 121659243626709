import React, { useState } from "react";
import { bool, string } from "prop-types";
import YouTube from "react-youtube";
import Section from "../Base/Section";
import Image from "../../components/ImageQuerys/LargeFeaturesImages";
import topImg from "../../images/svg/vector-22.svg";
import bottomImg from "../../images/svg/vector-23.svg";
import play from "../../images/svg/play.svg";
import CtaPrimary from "../Base/CtaPrimary";

const VideoQuote = ({
  className,
  quote,
  person,
  location,
  thumbnailImageName,
  videoId,
  withImageOnly,
  url,
  ctaTitle,

}) => {
  const [playing, setPlaying] = useState(false);

  const startPlaying = () => {
    setPlaying(true);
  };
  const endPlaying = () => {
    setPlaying(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  return (
    <Section
      className={`
        flex flex-col lg:flex-row justify-between items-stretch
        gap-8
        ${className}
      `}
    >
      <div
        className={`
        flex flex-col justify-between
        rounded-[16px] bg-primary-30  w-full
        p-4 md:p-8 xl:p-10 
      `}
      >
        <img alt="background" src={topImg} className="max-w-[21px]" />

        <div className="mt-10 lg:mt-0">
          <p className="text-black text-[18px] xl:text-[24px]">{quote}</p>
          <div className="text-primary text-[18px] xl:text-[24px] mt-10">
            {person}
          </div>
          <div className="text-black-200 text-[14px] mt-2">{location}</div>
          {url && (
            <CtaPrimary
              className="mt-10 font-bold"
              title={ctaTitle}
              target={url}
            />
          )}
        </div>

        <img
          alt="background"
          src={bottomImg}
          className="max-w-[66px] self-end"
        />
      </div>

      {!playing && !withImageOnly && (
        <div className="w-full relative">
          <Image imageName={thumbnailImageName} className="" />

          <button
            type="button"
            className={`
            absolute left-[50%] top-[50%] mx-auto bg-primary border-none rounded-full
            text-white text-p font-bold py-4 px-10
            hover:underline cursor-pointer
            flex items-center
            min-w-[215px]
          `}
            style={{ transform: "translate(-50%, -50%)" }}
            onClick={startPlaying}
          >
            Watch Video <img alt="play button" src={play} className="ml-4" />
          </button>
        </div>
      )}

      {
        playing && !withImageOnly && (
          <div className="w-full relative">
            <YouTube
              videoId={videoId}
              onEnd={endPlaying}
              onReady={onPlayerReady}
              className="min-h-[400px] md:min-h-[600px] rounded-[16px]"
              opts={{
                playerVars: {
                  autoplay: 1,
                },
                height: "auto",
                width: "100%",
              }}
            />
          </div>
        )
      }

      { withImageOnly && (
        <div className="w-full relative">
          <Image imageName={thumbnailImageName} className="" />
        </div>
      )}
    </Section>
  );
};

VideoQuote.propTypes = {
  className: string,
  quote: string.isRequired,
  person: string.isRequired,
  location: string,
  thumbnailImageName: string.isRequired,
  videoId: string,
  withImageOnly: bool,
  url: string,
  ctaTitle: string,
};

VideoQuote.defaultProps = {
  className: "",
  location: null,
  videoId: null,
  withImageOnly: false,
  url: "",
  ctaTitle: "",
};

export default VideoQuote;
