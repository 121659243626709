// eslint-disable-next-line import/prefer-default-export
export const newYorkSuccess = {
  title: "Client success",
  subTitle: "",
};

export const newYorktHero = {
  title:
    "NYC’s top-rated restaurant POS system. An end-to-end restaurant solution",
  subtext:
    "Online ordering, digital reservations & waitlisting, elite reporting, labor management software, and more to keep your guests and employees happy.",
  heroImg: "video_thumb_home.png",
  imageClassName: "fixed-width",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/new-york/demo",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "new-york-video-poster.png",
    videoUrlId: "aeLjiqXkwJU",
  },
};

export const momoyaSlides = [
  {
    image: "momoya.png",
    companyName: "Momoya",
    companyIs: "Casual dining",
    companyLogo: "momoya-logo.png",
    address: "New York City, NY",
    owner: "KwangHo Lee",
    website: "www.momoyanyc.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "New York's top sushi restaurant, Momoya needed to streamline operations, simplify the payment process, integrate with delivery apps, and get remote access to reports. With SpotOn's cloud-based platform, owner KwangHo Lee has seen the impact on his daily orders, tip percentages, and labor cost.",
    url: "/case-studies/momoya/",
    ctaTitle: "Read the story",
    info: [
      {
        numbers: "10%",
        numberType: "increase in daily orders",
      },
      {
        numbers: "4%",
        numberType: "increase in average tips",
      },
    ],
  },
];

export const posTerminalFeatures = {
  sectionTitle: "SpotOn Restaurant Point-of-Sale",
  sectionNavId: "pos-terminal",
  featureBlocks: [
    {
      blockTitle: "Software tailored just for you",
      blockSubTitle:
        "Get an end-to-end, personalized solution with all the features you need to maximize throughput, grow revenue, and improve your guest experience.",
      blockList: [
        "Point-of-sale",
        "Custom menu build",
        "Table layouts",
        "Labor management",
        "Multi-location management",
      ],
      blockImg: "pos-terminal-new.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/new-york/demo",
      },
    },
  ],
};

export const quoteWithVideo = {
  quote: `“SpotOn is not just a point-of-sale. It’s a communication device; it’s a reporting device; it’s a labor management tool. If you’re looking for a POS system that’s going to help you run your business, it’s SpotOn.”`,
  person: "Bret Csencsitz",
  location: "Managing Partner, Gotham Restaurant",
  thumbnailImageName: "new-york-landing-page.png",
  withImageOnly: true,
};

export const unifiedExportLargeFeatures = [posTerminalFeatures];

export const newYorkWhiteGlove = {
  mainTitle: "Expert local service and support",
  title: "White-glove service and support",
  content:
    "You have a lot on your plate, but with SpotOn, you’re no longer on your own. Work with our local team or use our browser-based web portal to set up front-of-house policies, monitor operations and staffing assignments, and access customer analytics—all with a local, dedicated representative and 24/7 personalized support.",
  contentList: [
    "Premium in-person hardware installation",
    "Face-to-face staff training from SpotOn specialist",
    "Ongoing virtual training for staff and management",
    "24/7 support from SpotOn employees included",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Get a personalized demo",
    ctaTarget: "/lp/new-york/demo",
  },
};

export const newYorkWebsite = {
  ctaInfoSecondary: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
  sectionNavId: "website-builder",
  featureBlocks: [
    {
      blockTitle: "Need a new website? We'll build it for you—fast",
      blockSubTitle:
        "Why pay thousands or waste valuable time building a site yourself when SpotOn can do it all for you?",
      blockList: [
        "Custom website build with your images and branding",
        "Online ordering with POS integration",
        "Quick turnaround time—in as little as 48 hours!",
        "Search engine optimization (SEO) to help customers find you online",
        "Hosting, domain name, self-service dashboard, and lifetime support included",
      ],
      blockImg: "laptop-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
    {
      blockTitle: "Reach more hungry guests with Google",
      blockSubTitle:
        "SpotOn's POS for restaurants makes it easier than ever for you to take advantage of Google features. Allow your clients to find you faster and order from your restaurant’s Google my Business profile.",
      blockList: [
        {
          title: "Google my Business",
          subtext:
            "Claim your profile with SpotOn in minutes, not weeks! Easily take control of your online presence and make it easy for customers to find your address, website, hours, and phone number.",
        },
        {
          title: "Order with Google",
          subtext:
            "Guests search for what they’re craving, find your restaurant, and order directly from your Business profile with ease. No apps. No logins. Easy.",
        },
      ],
      blockImg: "order-with-google.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/restaurant-pos/demo",
      },
    },
  ],
};

export const restaurantVideo = {
  title:
    "Run & grow your business with SpotOn's restaurant point-of-sales systems",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  posterWrapperClass: "",
  videoUrlId: "wx4JxSMsC4s",
};

export const restaurantCta = {
  title: "Book your consultation.",
  subtext:
    "Learn about everything that you may need working with different SpotOn products.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurant-pos/demo",
  },
};

export const restaurantFAQs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "Visit our Help Center",
    ctaTarget: "https://help.spoton.com/",
  },
  list: [
    {
      title: "How much does a restaurant POS cost?",
      body: `Restaurant POS systems typically range in pricing from $60 - $300 per month for software (per station) and from $700 - $1,700 for the POS hardware. Additional services, such as integrated online ordering and digital loyalty programs, usually cost extra. SpotOn offers affordable pricing options and custom configurations that are built to fit your specific needs and budget. <a href="https://www.spoton.com/restaurant-pos/demo" target="_blank" >Contact our team </a> to learn more and get a personalized quote."`,
    },
    {
      title: "Which POS system should I choose for my restaurant?",
      body: `The type of POS system you will want to choose depends on your restaurant concept. For example, counter-service concepts generally benefit from 1 or more countertop POS stations with customer-facing displays, KDS, and perhaps a handheld POS device for line-busting. Meanwhile, full-service restaurants typically can benefit from 1 or more POS stations and multiple handhelds to speed up table turn times. SpotOn offers flexible technology to fit these scenarios and more, with integrated online ordering and  <a "https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="_blank" >QR ordering</a>, and more.`,
    },
    {
      title: "Which businesses can use a restaurant POS system?",
      body: `All restaurants can benefit from using a restaurant POS system. SpotOn Restaurant is designed to work across several restaurant categories, including <a href="https://spoton.com/blog/restaurant-profit-margins-casual-dining/" target="_blank" >casual dining </a>, <a href="https://www.spoton.com/fine-dining-pos/" target="_blank" >fine dining</a>, <a href="https://spoton.com/blog/restaurant-profit-margins-fast-casual/" target="_blank" >fast casual</a>, <a href="https://spoton.com/blog/restaurant-profit-margins-quick-service/" target="_blank" >quick service</a>, <a href="https://www.spoton.com/nightclub-and-bar-pos/" target="_blank" >bars & nightclubs</a>, <a href="https://www.spoton.com/food-truck-pos/" target="_blank" >food trucks</a>, and pizzarias. Because of its customizability, SpotOn components can be mixed and matched according to your restaurant's specific needs.`,
    },
  ],
};

export const newYorkSubverticals = {
  title: "Who we serve",
  subverticalInfo: [
    {
      target: "/fine-dining-pos",
      iconImg: "fine-dining.png",
      subverticalName: "Fine dining",
      subverticalContent:
        "Offer your guests a seamless and refined dining experience with a custom end-to-end point-of-sale solution that is tailor-made to streamline operations.",
    },
    {
      target: "/quick-service-pos",
      iconImg: "qsr.png",
      subverticalName: "Quick-serve",
      subverticalContent:
        "Keep the line moving fast and stand out from your competitors with integrated digital marketing and loyalty at checkout.",
    },
    {
      target: "/casual-dining-pos",
      iconImg: "full-service.png",
      subverticalName: "Casual full-service",
      subverticalContent:
        "Let your customers order and pay their way with a flexible configuration that makes sense for your restaurant.",
    },
  ],
};

export const advisoryBoard = {
  title: "Meet our Restaurant Advisory Council",
  subtext:
    "This elite group of restaurant owners, investors, and entrepreneurs provides real-world expertise and insights to ensure SpotOn's solutions are built to help you maximize profits.",
  SecondaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/company/advisory-board",
  },
  fatText: "Advisory Council",
  image: "advisory_board.png",
  alt: "restaurant Pos",
  noBgShapes: true,
  reverseOrder: true,
  assetStyles: { maxWidth: 600, margin: "0px auto 40px" },
};
